import React, { useEffect, useState, useContext, useCallback } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { AuthContext } from '../context/AuthContext';
import { Bait } from '../interfaces/Bait';
import api from '../services/api';
import { CircularProgress, Box, Button } from '@mui/material';
import { toast } from 'react-toastify';
import L, { Icon } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { useNavigate } from 'react-router-dom';

import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

import beehiveIcon from '../assets/beehive.png';

delete (L.Icon.Default.prototype as any)._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: markerIcon2x,
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
});

const customBeehiveIcon = new L.Icon({
  iconUrl: beehiveIcon,
  iconSize: [30, 30],
  iconAnchor: [15, 30],
  popupAnchor: [0, -30],
});

const defaultMapCenter: L.LatLngExpression = [37.4219983, -122.084];

const BaitMap: React.FC = () => {
  const { user } = useContext(AuthContext);
  const [baits, setBaits] = useState<Bait[]>([]);
  const [userLocation, setUserLocation] = useState<L.LatLngExpression | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [averageLocation, setAverageLocation] = useState<L.LatLngExpression | null>(null);
  const navigate = useNavigate();

  const fetchBaits = useCallback(async () => {
    if (!user) {
      toast.error('Please log in to see baits.');
      setIsLoading(false);
      return;
    }

    try {
      const response = await api.get(`/bait/user/${user.id}`);
      const fetchedBaits: Bait[] = response.data;
      setBaits(fetchedBaits);
      setIsLoading(false);
    } catch (error) {
      toast.error('Error fetching baits.');
      setIsLoading(false);
    }
  }, [user]);

  const calculateAverageLocation = (baitsList: Bait[]) => {
    if (baitsList.length === 0) return null;

    const totalCoordinates = baitsList.reduce(
      (accumulated, bait) => {
        const [latitude, longitude] = bait.localizacao.split(',').map(coord => parseFloat(coord.trim()));
        return { lat: accumulated.lat + latitude, lng: accumulated.lng + longitude };
      },
      { lat: 0, lng: 0 }
    );

    return [totalCoordinates.lat / baitsList.length, totalCoordinates.lng / baitsList.length] as L.LatLngExpression;
  };

  useEffect(() => {
    if (user) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const userPos: L.LatLngExpression = [position.coords.latitude, position.coords.longitude];
            setUserLocation(userPos);
            fetchBaits();
          },
          () => {
            toast.error('Unable to access your location.');
            fetchBaits();
          }
        );
      } else {
        toast.error('Geolocation not supported.');
        fetchBaits();
      }
    }
  }, [fetchBaits, user]);

  useEffect(() => {
    setAverageLocation(calculateAverageLocation(baits));
  }, [baits]);

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <MapContainer
      style={{ width: '100%', height: '100vh' }}
      center={averageLocation || defaultMapCenter}
      zoom={14}
      scrollWheelZoom={true}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
      />
      {userLocation && (
        <Marker position={userLocation}>
          <Popup>Your Location</Popup>
        </Marker>
      )}
      {baits.map((bait) => {
        const [latitude, longitude] = bait.localizacao.split(',').map(coord => parseFloat(coord.trim()));
        return (
          <Marker key={bait.id} position={[latitude, longitude]} icon={customBeehiveIcon}>
            <Popup>
              <div>
                <h3>Bait: {bait.identificador}</h3>
                <p><strong>Description:</strong> {bait.descricao || 'N/A'}</p>
                <p><strong>Material:</strong> {bait.material}</p>
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={() => navigate(`/baits/${bait.id}`)}
                >
                  View Details
                </Button>
              </div>
            </Popup>
          </Marker>
        );
      })}
    </MapContainer>
  );
};

export default React.memo(BaitMap);
