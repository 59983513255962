// src/components/DeleteAccount.tsx
import React, { useState, useContext, useEffect } from 'react';
import {
  Container,
  Typography,
  Box,
  TextField,
  Alert,
  Stepper,
  Step,
  StepLabel,
  Paper,
  useTheme,
  useMediaQuery,
  CircularProgress,
  Button,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { styled, Theme } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LockIcon from '@mui/icons-material/Lock';
import VerifiedIcon from '@mui/icons-material/Verified';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import api from '../services/api';
import { AuthContext } from '../context/AuthContext';
import Layout from './Layout';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

// Conector Personalizado com gradiente e bordas arredondadas
const CustomConnector = styled(StepConnector)(({ theme }: { theme: Theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 20,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.secondary.main} 90%)`,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.secondary.main} 90%)`,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 1.8,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.grey[400],
    borderRadius: 1,
    transition: theme.transitions.create('background-color', {
      duration: theme.transitions.duration.shortest,
    }),
  },
}));

// Estilização personalizada para um container mais moderno
const StyledPaper = styled(Paper)(({ theme }: { theme: Theme }) => ({
  padding: theme.spacing(6),
  borderRadius: theme.spacing(3),
  boxShadow: theme.shadows[10],
  background: theme.palette.background.default,
}));

// Estilização personalizada para o Stepper
const CustomStepper = styled(Stepper)(({ theme }: { theme: Theme }) => ({
  background: 'transparent',
  padding: 0,
  marginBottom: theme.spacing(4),
}));

// Estilização personalizada para os botões (Extender Button do MUI)
const GradientButton = styled(Button)(({ theme }: { theme: Theme }) => ({
  background: `linear-gradient(45deg, ${theme.palette.error.main} 30%, ${theme.palette.error.dark} 90%)`,
  color: '#fff',
  padding: theme.spacing(1.5),
  borderRadius: theme.spacing(2),
  boxShadow: theme.shadows[3],
  transition: 'all 0.3s ease',
  '&:hover': {
    boxShadow: theme.shadows[6],
    transform: 'translateY(-2px)',
    background: `linear-gradient(45deg, ${theme.palette.error.dark} 30%, ${theme.palette.error.main} 90%)`,
  },
  '&:disabled': {
    opacity: 0.7,
    cursor: 'not-allowed',
  },
}));

const SuccessButton = styled(Button)(({ theme }: { theme: Theme }) => ({
  background: `linear-gradient(45deg, ${theme.palette.success.main} 30%, ${theme.palette.success.dark} 90%)`,
  color: '#fff',
  padding: theme.spacing(1.5),
  borderRadius: theme.spacing(2),
  boxShadow: theme.shadows[3],
  transition: 'all 0.3s ease',
  '&:hover': {
    boxShadow: theme.shadows[6],
    transform: 'translateY(-2px)',
    background: `linear-gradient(45deg, ${theme.palette.success.dark} 30%, ${theme.palette.success.main} 90%)`,
  },
  '&:disabled': {
    opacity: 0.7,
    cursor: 'not-allowed',
  },
}));

// Ícones personalizados para cada etapa
const stepIcons: { [index: number]: JSX.Element } = {
  0: <LockIcon />,
  1: <VerifiedIcon />,
  2: <CheckCircleIcon />,
};

const DeleteAccount: React.FC = () => {
  const { setToken } = useContext(AuthContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [activeStep, setActiveStep] = useState<number>(0);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [verificationCode, setVerificationCode] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [timeLeft, setTimeLeft] = useState<number>(0);
  const [canResend, setCanResend] = useState<boolean>(false);

  // Estados para controlar a visibilidade das senhas
  const [showPasswordStep1, setShowPasswordStep1] = useState<boolean>(false);
  const [showPasswordStep3, setShowPasswordStep3] = useState<boolean>(false);

  // Estados de erro para cada campo
  const [emailError, setEmailError] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');
  const [verificationCodeError, setVerificationCodeError] = useState<string>('');
  const [confirmPasswordError, setConfirmPasswordError] = useState<string>('');

  const steps = ['Autenticação', 'Verificação', 'Confirmação'];

  // Efeito para o contador de tempo
  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prev) => prev - 1);
      }, 1000);
    } else if (timeLeft === 0 && activeStep === 1) {
      setCanResend(true);
    }
    return () => clearInterval(timer);
  }, [timeLeft, activeStep]);

  const sendVerificationCode = async () => {
    try {
      await api.post('/auth/send-verification-code', { email });
      setMessage('Código de verificação reenviado para o seu email.');
      setTimeLeft(60);
      setCanResend(false);
    } catch (err: any) {
      setError(err.response?.data?.message || 'Erro ao reenviar o código.');
    }
  };

  const handleResendCode = async () => {
    setError('');
    setMessage('');
    setLoading(true);
    await sendVerificationCode();
    setLoading(false);
  };

  const validateStep0 = (): boolean => {
    let valid = true;
    if (!email.trim()) {
      setEmailError('O campo de email é obrigatório.');
      valid = false;
    } else {
      // Opcional: Validar formato de email
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        setEmailError('Por favor, insira um email válido.');
        valid = false;
      }
    }

    if (!password.trim()) {
      setPasswordError('O campo de senha é obrigatório.');
      valid = false;
    }

    return valid;
  };

  const validateStep1 = (): boolean => {
    let valid = true;
    if (!verificationCode.trim()) {
      setVerificationCodeError('O campo de código de verificação é obrigatório.');
      valid = false;
    }
    return valid;
  };

  const validateStep2 = (): boolean => {
    let valid = true;
    if (!confirmPassword.trim()) {
      setConfirmPasswordError('O campo de confirmação de senha é obrigatório.');
      valid = false;
    }
    // Opcional: Verificar se a senha de confirmação corresponde à senha original
    if (confirmPassword && confirmPassword !== password) {
      setConfirmPasswordError('As senhas não correspondem.');
      valid = false;
    }
    return valid;
  };

  const handleNext = async () => {
    setError('');
    setMessage('');

    if (activeStep === 0) {
      // Validação do Passo 1
      if (!validateStep0()) {
        return;
      }
    } else if (activeStep === 1) {
      // Validação do Passo 2
      if (!validateStep1()) {
        return;
      }
    }

    setLoading(true);
    try {
      if (activeStep === 0) {
        // Passo 1: Autenticação do usuário
        const loginResponse = await api.post('/auth/login', { email, password });
        const { access_token } = loginResponse.data;
        setToken(access_token);

        // Enviar código de verificação
        await api.post('/auth/send-verification-code', { email });
        setMessage('Código de verificação enviado para o seu email.');
        setTimeLeft(60);
        setCanResend(false);

        // Avançar para o próximo passo após envio do código
        setActiveStep((prev) => prev + 1);
      } else if (activeStep === 1) {
        // Passo 2: Verificar o código
        const verifyResponse = await api.post('/auth/verify-code', { email, code: verificationCode });
        console.log(verifyResponse);
        if (verifyResponse.data === 'verified') {
          setMessage('Código verificado. Agora você pode deletar sua conta.');
          setActiveStep((prev) => prev + 1);
        } else if (verifyResponse.data === 'expired') {
          setError('O código expirou. Por favor, solicite um novo código.');
        } else {
          setError('Código inválido.');
        }
      }
    } catch (err: any) {
      if (activeStep === 1) {
        if (err.response?.data?.status === 'expired') {
          setError('O código expirou. Por favor, solicite um novo código.');
        } else {
          setError(err.response?.data?.message || 'Código inválido.');
        }
      } else {
        setError(err.response?.data?.message || 'Ocorreu um erro.');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteAccount = async () => {
    setError('');
    setMessage('');

    // Validação do Passo 3
    if (!validateStep2()) {
      return;
    }

    setLoading(true);
    try {
      await api.delete('/auth/delete-account', { data: { password: confirmPassword } });
      setMessage('Conta deletada com sucesso.');
      setToken(null);
      setActiveStep((prev) => prev + 1);
      // Opcional: Redirecionar para a página inicial ou de login
    } catch (err: any) {
      setError(err.response?.data?.message || 'Erro ao deletar a conta.');
    } finally {
      setLoading(false);
    }
  };

  // Funções para alternar a visibilidade das senhas
  const handleTogglePasswordStep1 = () => {
    setShowPasswordStep1((prev) => !prev);
  };

  const handleTogglePasswordStep3 = () => {
    setShowPasswordStep3((prev) => !prev);
  };

  // Estilização do InputAdornment e IconButton para garantir que o fundo corresponda ao TextField
  const adornmentStyles = {
    backgroundColor: theme.palette.grey[100],
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    // Remove padding/margin que podem afetar o alinhamento
    margin: 0,
    padding: 0,
  };

  const iconButtonStyles = {
    color: theme.palette.text.secondary,
    padding: theme.spacing(0.5),
    // Remove fundo padrão do IconButton
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  };

  return (
    <Layout>
      <Container maxWidth="sm" sx={{ mt: isMobile ? 4 : 8, mb: isMobile ? 4 : 8 }}>
        <StyledPaper>
          <Box sx={{ textAlign: 'center' }}>
            <Typography
              variant={isMobile ? 'h5' : 'h4'}
              component="h1"
              gutterBottom
              sx={{ fontWeight: 700, color: theme.palette.text.primary }}
            >
              Deletar Conta
            </Typography>
            <CustomStepper
              activeStep={activeStep}
              alternativeLabel
              connector={<CustomConnector />}
            >
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepLabel
                    StepIconComponent={() => (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          width: 40,
                          height: 40,
                          borderRadius: '50%',
                          background:
                            activeStep > index
                              ? theme.palette.success.light
                              : theme.palette.grey[300],
                          color:
                            activeStep > index
                              ? theme.palette.success.main
                              : theme.palette.text.primary,
                          boxShadow: activeStep > index ? theme.shadows[4] : 'none',
                        }}
                      >
                        {stepIcons[index]}
                      </Box>
                    )}
                    sx={{
                      '& .MuiStepLabel-label': {
                        fontSize: isMobile ? '0.8rem' : '1rem',
                        fontWeight: 500,
                        color: theme.palette.text.secondary,
                      },
                    }}
                  >
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </CustomStepper>
            {message && (
              <Alert
                severity="success"
                sx={{
                  mb: 2,
                  borderRadius: 2,
                  boxShadow: theme.shadows[2],
                  animation: 'fadeIn 0.5s',
                }}
              >
                {message}
              </Alert>
            )}
            {error && (
              <Alert
                severity="error"
                sx={{
                  mb: 2,
                  borderRadius: 2,
                  boxShadow: theme.shadows[2],
                  animation: 'fadeIn 0.5s',
                }}
              >
                {error}
              </Alert>
            )}
            {activeStep === 0 && (
              <Box component="form" sx={{ mt: 2 }}>
                <TextField
                  label="Email"
                  variant="filled"
                  fullWidth
                  required
                  margin="normal"
                  type="email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    if (emailError) setEmailError('');
                  }}
                  error={!!emailError}
                  helperText={emailError}
                  sx={{
                    '& .MuiFilledInput-root': {
                      backgroundColor: theme.palette.grey[100],
                      borderRadius: 2,
                    },
                  }}
                />
                <TextField
                  label="Senha"
                  variant="filled"
                  fullWidth
                  required
                  margin="normal"
                  type={showPasswordStep1 ? 'text' : 'password'}
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    if (passwordError) setPasswordError('');
                  }}
                  error={!!passwordError}
                  helperText={passwordError}
                  sx={{
                    '& .MuiFilledInput-root': {
                      backgroundColor: theme.palette.grey[100],
                      borderRadius: 2,
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" sx={adornmentStyles}>
                        <IconButton
                          onClick={handleTogglePasswordStep1}
                          edge="end"
                          aria-label="toggle password visibility"
                          sx={iconButtonStyles}
                        >
                          {showPasswordStep1 ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <GradientButton
                  onClick={handleNext}
                  disabled={loading}
                  fullWidth
                  sx={{ mt: 2 }}
                >
                  {loading ? <CircularProgress size={24} color="inherit" /> : 'Enviar Código de Verificação'}
                </GradientButton>
              </Box>
            )}
            {activeStep === 1 && (
              <Box component="form" sx={{ mt: 2 }}>
                <TextField
                  label="Código de Verificação"
                  variant="filled"
                  fullWidth
                  required
                  margin="normal"
                  value={verificationCode}
                  onChange={(e) => {
                    setVerificationCode(e.target.value);
                    if (verificationCodeError) setVerificationCodeError('');
                  }}
                  error={!!verificationCodeError}
                  helperText={verificationCodeError}
                  sx={{
                    '& .MuiFilledInput-root': {
                      backgroundColor: theme.palette.grey[100],
                      borderRadius: 2,
                    },
                  }}
                />
                <GradientButton
                  onClick={handleNext}
                  disabled={loading}
                  fullWidth
                  sx={{ mt: 2 }}
                >
                  {loading ? <CircularProgress size={24} color="inherit" /> : 'Verificar Código'}
                </GradientButton>
                {canResend ? (
                  <Button
                    onClick={handleResendCode}
                    disabled={loading}
                    fullWidth
                    sx={{ mt: 2, textTransform: 'none' }}
                  >
                    {loading ? <CircularProgress size={24} color="inherit" /> : 'Reenviar Código'}
                  </Button>
                ) : (
                  <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
                    Reenviar código disponível em: {timeLeft} segundos
                  </Typography>
                )}
              </Box>
            )}
            {activeStep === 2 && (
              <Box sx={{ mt: 2, textAlign: 'left' }}>
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{ mb: 2, color: theme.palette.text.primary }}
                >
                  Tem certeza que deseja deletar sua conta? Esta ação é irreversível.
                </Typography>
                <TextField
                  label="Confirme sua Senha"
                  variant="filled"
                  fullWidth
                  required
                  margin="normal"
                  type={showPasswordStep3 ? 'text' : 'password'}
                  value={confirmPassword}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                    if (confirmPasswordError) setConfirmPasswordError('');
                  }}
                  error={!!confirmPasswordError}
                  helperText={confirmPasswordError}
                  sx={{
                    '& .MuiFilledInput-root': {
                      backgroundColor: theme.palette.grey[100],
                      borderRadius: 2,
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" sx={adornmentStyles}>
                        <IconButton
                          onClick={handleTogglePasswordStep3}
                          edge="end"
                          aria-label="toggle password visibility"
                          sx={iconButtonStyles}
                        >
                          {showPasswordStep3 ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <GradientButton
                  onClick={handleDeleteAccount}
                  disabled={loading}
                  fullWidth
                  sx={{ mt: 2 }}
                >
                  {loading ? <CircularProgress size={24} color="inherit" /> : 'Deletar Conta'}
                </GradientButton>
              </Box>
            )}
            {activeStep === 3 && (
              <Box sx={{ mt: 4, textAlign: 'center' }}>
                <CheckCircleIcon sx={{ fontSize: 60, color: theme.palette.success.main }} />
                <Typography variant="h6" color="success.main" sx={{ mt: 2 }}>
                  Sua conta foi deletada com sucesso.
                </Typography>
                <SuccessButton
                  onClick={() => {
                    // Opcional: Redirecionar para a página inicial ou de login
                  }}
                  sx={{ mt: 3 }}
                >
                  Voltar para a Página Inicial
                </SuccessButton>
              </Box>
            )}
          </Box>
        </StyledPaper>
      </Container>
    </Layout>
  );
};

export default DeleteAccount;
