// src/components/MapPage.tsx

import React, { useState } from 'react';
import Layout from './Layout';
import BaitMap from './BaitMap';
import {
  Typography,
  Box,
  ToggleButton,
  ToggleButtonGroup,
  useMediaQuery,
  useTheme,
  Fade,
} from '@mui/material';
import MapIcon from '@mui/icons-material/Map';
import BeehiveIcon from '@mui/icons-material/BugReport'; // Substitua pelo ícone apropriado se desejar
import SwarmMap from './SwarmMap';

const MapPage: React.FC = () => {
  // Estado local para armazenar o tipo de mapa selecionado
  const [mapType, setMapType] = useState<'isca' | 'enxame'>('isca');

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // Função para lidar com a mudança de mapa
  const handleMapChange = (
    event: React.MouseEvent<HTMLElement>,
    newMapType: 'isca' | 'enxame' | null
  ) => {
    if (newMapType !== null) {
      setMapType(newMapType);
    }
  };

  return (
    <Layout>
      <Box
        sx={{
          mt: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          px: 2,
        }}
      >

        {/* Grupo de Botões para Seleção de Mapa */}
        <ToggleButtonGroup
          value={mapType}
          exclusive
          onChange={handleMapChange}
          aria-label="Tipo de Mapa"
          sx={{
            mb: 4,
            backgroundColor: 'background.paper',
            borderRadius: 2,
            boxShadow: 3,
          }}
        >
          <ToggleButton
            value="isca"
            aria-label="Mapa de Iscas"
            sx={{
              px: isSmallScreen ? 1 : 3,
              py: isSmallScreen ? 0.5 : 1,
              textTransform: 'none',
              fontSize: isSmallScreen ? '0.8rem' : '1rem',
            }}
          >
            <BeehiveIcon sx={{ mr: 1 }} />
            Iscas
          </ToggleButton>
          <ToggleButton
            value="enxame"
            aria-label="Mapa de Enxames"
            sx={{
              px: isSmallScreen ? 1 : 3,
              py: isSmallScreen ? 0.5 : 1,
              textTransform: 'none',
              fontSize: isSmallScreen ? '0.8rem' : '1rem',
            }}
          >
            <MapIcon sx={{ mr: 1 }} />
            Enxames
          </ToggleButton>
        </ToggleButtonGroup>

        {/* Renderização Condicional dos Mapas com Animação */}
        <Box
          sx={{
            width: '100%',
            height: '600px',
            position: 'relative',
          }}
        >
          <Fade in={mapType === 'isca'} timeout={500} unmountOnExit>
            <Box
              sx={{
                position: 'absolute',
                width: '100%',
                height: '100%',
              }}
            >
              <BaitMap />
            </Box>
          </Fade>
          <Fade in={mapType === 'enxame'} timeout={500} unmountOnExit>
            <Box
              sx={{
                position: 'absolute',
                width: '100%',
                height: '100%',
              }}
            >
              <SwarmMap />
            </Box>
          </Fade>
        </Box>
      </Box>
    </Layout>
  );
};

export default MapPage;
