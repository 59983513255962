// src/components/LoginPage.tsx
import React, { useState, useContext } from 'react';
import {
  TextField,
  Container,
  Typography,
  Box,
  Alert,
  Paper,
  Button,
  Avatar,
  InputAdornment,
  IconButton,
  CircularProgress,
} from '@mui/material';
import { styled, Theme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import LockIcon from '@mui/icons-material/Lock';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import api from '../services/api';
import { AuthContext } from '../context/AuthContext';
import Layout from './Layout';

const StyledPaper = styled(Paper)(({ theme }: { theme: Theme }) => ({
  padding: theme.spacing(6),
  borderRadius: theme.spacing(3),
  boxShadow: theme.shadows[10],
  background: theme.palette.background.default,
}));

const CustomButton = styled(Button)(({ theme }: { theme: Theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: '#fff',
  padding: theme.spacing(1.5),
  borderRadius: theme.spacing(2),
  boxShadow: theme.shadows[3],
  transition: 'all 0.3s ease',
  '&:hover': {
    boxShadow: theme.shadows[6],
    transform: 'translateY(-2px)',
    backgroundColor: theme.palette.primary.dark,
  },
  '&:disabled': {
    opacity: 0.7,
    cursor: 'not-allowed',
  },
}));

const LoginPage: React.FC = () => {
  const { setToken, setRefreshToken, setUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [error, setError] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      const response = await api.post('/auth/login', { email, password });
      const { access_token, refresh_token, user_id, planType } = response.data;

      if (planType !== 'PRO') {
        setError('Somente usuários PRO podem fazer login.');
        setLoading(false);
        return;
      }

      setToken(access_token);
      setRefreshToken(refresh_token);
      setUser({ id: user_id, email, planType });

      navigate('/dashboard');
    } catch (err) {
      console.error(err);
      setError('Credenciais inválidas ou usuário não encontrado.');
    } finally {
      setLoading(false);
    }
  };

  // Função para alternar a visibilidade da senha
  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <Layout>
      <Container component="main" maxWidth="sm" sx={{ mt: 8, mb: 8 }}>
        <StyledPaper>
          <Box sx={{ textAlign: 'center' }}>
            <Avatar
              sx={{
                m: 'auto',
                bgcolor: 'primary.main',
                width: 72,
                height: 72,
                mb: 2,
              }}
            >
              <LockIcon sx={{ fontSize: 40 }} />
            </Avatar>
            <Typography
              variant="h4"
              component="h1"
              gutterBottom
              sx={{ fontWeight: 700 }}
            >
              Bem-vindo ao Pollen Web!
            </Typography>
            <Typography variant="subtitle1" sx={{ mb: 4 }}>
              Faça login para continuar
            </Typography>
            {error && (
              <Alert
                severity="error"
                sx={{
                  mb: 2,
                  borderRadius: 2,
                  boxShadow: (theme) => theme.shadows[2],
                }}
              >
                {error}
              </Alert>
            )}
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
              <TextField
                label="Endereço de Email"
                variant="filled"
                fullWidth
                required
                margin="normal"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autoComplete="email"
                sx={{
                  '& .MuiFilledInput-root': {
                    backgroundColor: (theme) => theme.palette.grey[100],
                    borderRadius: 2,
                  },
                }}
              />
              <TextField
                label="Senha"
                variant="filled"
                fullWidth
                required
                margin="normal"
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                autoComplete="current-password"
                sx={{
                  '& .MuiFilledInput-root': {
                    backgroundColor: (theme) => theme.palette.grey[100],
                    borderRadius: 2,
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleTogglePasswordVisibility}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <CustomButton
                type="submit"
                fullWidth
                disabled={loading}
                sx={{ mt: 4 }}
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  'Entrar'
                )}
              </CustomButton>
            </Box>
          </Box>
        </StyledPaper>
      </Container>
    </Layout>
  );
};

export default LoginPage;
