import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Box,
  Button,
  Grid,
  useTheme,
  useMediaQuery,
  CircularProgress,
  Fade,
  IconButton,
} from '@mui/material';
import { SiGoogleplay, SiAppstore } from 'react-icons/si';
import { FaInstagram, FaFacebookF } from 'react-icons/fa';
import { styled } from '@mui/system';
import { useInView } from 'react-intersection-observer';
import logo from '../assets/logo.png';
import appMainScreenshot from '../assets/screenshot-main.png';
import appMapScreenshot from '../assets/screenshot-map.png';
import hiveTimelineScreenshot from '../assets/screenshot-timeline.png';
import graphScreenshot from '../assets/screenshot-graph.png';
import Layout from './Layout';

// Styled Components

const Preloader = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 1300,
}));

const HeroSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(12, 2),
  background: `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
  color: '#ffffff',
}));

const FeaturesSection = styled(Box)(({ theme }) => ({
  padding: theme.spacing(8, 2),
  backgroundColor: theme.palette.background.paper,
}));

const FooterSection = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[900],
  color: theme.palette.common.white,
  padding: theme.spacing(2, 0),
  textAlign: 'center',
}));

const FooterContent = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

const FooterIcons = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  gap: theme.spacing(2),
  marginTop: theme.spacing(1),
}));

const ResponsiveImage = styled('img')(({ theme }) => ({
  width: '60%',
  maxWidth: '500px',
  height: 'auto',
  objectFit: 'contain',
  borderRadius: theme.shape.borderRadius,
}));

const FeatureImage = styled('img')(({ theme }) => ({
  width: '60%',
  maxWidth: '400px',
  height: 'auto',
  objectFit: 'contain',
  borderRadius: theme.shape.borderRadius,
}));

const FeatureContainer = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(8),
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

const FeatureImageBox = styled(Box)(({ theme }) => ({
  flex: 1,
  padding: theme.spacing(2),
}));

const FeatureTextBox = styled(Box)(({ theme }) => ({
  flex: 1,
  padding: theme.spacing(2),
}));

const FeatureTitle = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  fontWeight: 600,
}));

const FeatureDescription = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

// Componente FeatureItem

interface FeatureItemProps {
  title: string;
  description: string;
  image: string;
  reverse?: boolean;
}

const FeatureItem: React.FC<FeatureItemProps> = ({ title, description, image, reverse = false }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <Fade in={inView} timeout={1000}>
      <FeatureContainer ref={ref}>
        {isSmallScreen ? (
          // Em telas pequenas, sempre exibir texto primeiro
          <>
            <FeatureTextBox>
              <FeatureTitle variant="h5">{title}</FeatureTitle>
              <FeatureDescription variant="body2">{description}</FeatureDescription>
            </FeatureTextBox>
            <FeatureImageBox>
              <FeatureImage src={image} alt={`Captura de Tela de ${title}`} loading="lazy" />
            </FeatureImageBox>
          </>
        ) : reverse ? (
          // Em telas maiores, alternar conforme a prop 'reverse'
          <>
            <FeatureTextBox>
              <FeatureTitle variant="h5">{title}</FeatureTitle>
              <FeatureDescription variant="body2">{description}</FeatureDescription>
            </FeatureTextBox>
            <FeatureImageBox>
              <FeatureImage src={image} alt={`Captura de Tela de ${title}`} loading="lazy" />
            </FeatureImageBox>
          </>
        ) : (
          <>
            <FeatureImageBox>
              <FeatureImage src={image} alt={`Captura de Tela de ${title}`} loading="lazy" />
            </FeatureImageBox>
            <FeatureTextBox>
              <FeatureTitle variant="h5">{title}</FeatureTitle>
              <FeatureDescription variant="body2">{description}</FeatureDescription>
            </FeatureTextBox>
          </>
        )}
      </FeatureContainer>
    </Fade>
  );
};

const LandingPage: React.FC = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // Estado do Preloader
  const [isLoading, setIsLoading] = useState<boolean>(true);
  useEffect(() => {
    const timer = setTimeout(() => setIsLoading(false), 1500); // Simula o carregamento
    return () => clearTimeout(timer);
  }, []);

  // Dados das Funcionalidades
  const features = [
    {
      id: 1,
      title: 'Mapa Interativo',
      description:
        'Visualize a localização de todas as suas colmeias e iscas em um mapa interativo para melhor gerenciamento.',
      image: appMapScreenshot,
    },
    {
      id: 2,
      title: 'Linha do Tempo da Colmeia',
      description:
        'Acompanhe o histórico e as atividades de cada colmeia ao longo do tempo.',
      image: hiveTimelineScreenshot,
    },
    {
      id: 3,
      title: 'Gráficos Analíticos',
      description:
        'Analise dados detalhados sobre a produção de mel, vendas, capturas e outros fatores relevantes.',
      image: graphScreenshot,
    },
  ];

  return (
    <>
      {/* Preloader */}
      {isLoading && (
        <Preloader>
          <CircularProgress color="primary" />
        </Preloader>
      )}

      {/* Layout com NavigationBar */}
      <Layout>
        {/* Hero Section */}
        <HeroSection id="home">
          <Container maxWidth="lg">
            <Grid container spacing={4} alignItems="center">
              <Grid item xs={12} md={6}>
                <Typography color="text.primary" variant={isSmallScreen ? 'h4' : 'h2'} gutterBottom>
                  Pollen: A Solução Completa para Seu Meliponário
                </Typography>
                <Typography color="text.primary" variant="body1" paragraph>
                  Gerencie suas colmeias de forma inteligente e sustentável. Monitore a saúde das abelhas, otimize a produção de mel e promova o bem-estar do seu meliponário com nossas ferramentas inovadoras.
                </Typography>
                <Box sx={{ mt: 4, display: 'flex', gap: 2, flexWrap: 'wrap', flexDirection: 'column' }}>
                  {/* Botões com Ícones e Links */}
                  <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
                    <Button
                      variant="contained"
                      color="secondary"
                      startIcon={<SiGoogleplay size={24} />}
                      href="https://play.google.com/store/apps/details?id=com.pollen.app"
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{
                        textTransform: 'none',
                        paddingX: 2,
                        paddingY: 1,
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        borderRadius: '8px',
                        boxShadow: 'none',
                        backgroundColor: theme.palette.secondary.main,
                        '&:hover': {
                          boxShadow: 'none',
                          transform: 'none',
                          backgroundColor: theme.palette.secondary.dark,
                        },
                      }}
                    >
                      <Typography variant="button" sx={{ fontWeight: 600 }}>
                        Google Play
                      </Typography>
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      startIcon={<SiAppstore size={24} />}
                      disabled
                      sx={{
                        textTransform: 'none',
                        paddingX: 2,
                        paddingY: 1,
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        borderRadius: '8px',
                        boxShadow: 'none',
                        cursor: 'not-allowed',
                        backgroundColor: theme.palette.grey[500],
                        '&:hover': {
                          boxShadow: 'none',
                          transform: 'none',
                          backgroundColor: theme.palette.grey[500],
                        },
                      }}
                    >
                      <Typography variant="button" sx={{ fontWeight: 600 }}>
                        App Store
                      </Typography>
                    </Button>
                  </Box>
                  {/* Disclaimer Visível */}
                  <Box sx={{ mt: 1 }}>
                    <Typography variant="body2" color="text.secondary">
                      <strong>App Store (iOS):</strong> Lançamento em breve
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <ResponsiveImage
                    src={appMainScreenshot}
                    alt="Captura de Tela do Aplicativo"
                    loading="lazy"
                  />
                </Box>
              </Grid>
            </Grid>
          </Container>
        </HeroSection>

        {/* Features Section */}
        <FeaturesSection id="features">
          <Container maxWidth="lg">
            <Box textAlign="center" mb={8}>
              <Typography variant="h4" gutterBottom>
                Algumas de nossas ferramentas
              </Typography>
              <Typography variant="h6" color="text.secondary">
                Explore como o Pollen aprimora o monitoramento das abelhas e potencializa a produção de mel através de recursos avançados de monitoramento.
              </Typography>
            </Box>

            {features.map((feature, index) => (
              <FeatureItem
                key={feature.id}
                title={feature.title}
                description={feature.description}
                image={feature.image}
                reverse={index % 2 !== 0} // Alterna a posição da imagem e texto
              />
            ))}
          </Container>
        </FeaturesSection>

        {/* Rodapé Moderno e Compacto */}
        <FooterSection>
          <FooterContent maxWidth="lg">
            <Box>
              <img src={logo} alt="Pollen Logo" style={{ width: '80px' }} />
            </Box>

            <FooterIcons>
              <IconButton
                aria-label="Instagram"
                href="https://instagram.com/pollen.blog"
                target="_blank"
                rel="noopener noreferrer"
                color="inherit"
              >
                <FaInstagram size={24} />
              </IconButton>
              <IconButton
                aria-label="Facebook"
                href="https://facebook.com/seuperfil"
                target="_blank"
                rel="noopener noreferrer"
                color="inherit"
              >
                <FaFacebookF size={24} />
              </IconButton>
              {/* Adicione outros ícones de redes sociais, se necessário */}
            </FooterIcons>
            <Typography variant="body2" sx={{ mt: 1 }}>
              © {new Date().getFullYear()} Pollen. Todos os direitos reservados.
            </Typography>
          </FooterContent>
        </FooterSection>
      </Layout>
    </>
  );
};

export default LandingPage;
