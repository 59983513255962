// src/components/Dashboard.tsx

import React, { useEffect, useState, useContext } from 'react';
import {
  Container,
  Typography,
  Box,
  Grid,
  Paper,
  CircularProgress,
  useTheme,
  useMediaQuery,
  Skeleton,
} from '@mui/material';
import {
  Bar,
  Pie,
  Line,
} from 'react-chartjs-2';
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  PointElement,
  LineElement,
  TimeScale,
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import { DashboardResponse } from '../interfaces/Dashboard';
import api from '../services/api';
import Layout from './Layout';
import { AuthContext } from '../context/AuthContext';
import { styled } from '@mui/material/styles';
import { 
  TrendingUp, 
  TrendingDown, 
  ShoppingCart, 
  VolunteerActivism, 
  EmojiNature, 
  EmojiPeople 
} from '@mui/icons-material';

// Registrar os componentes necessários do Chart.js
Chart.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  PointElement,
  LineElement,
  TimeScale
);

// Estilização personalizada
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.spacing(2),
  boxShadow: theme.shadows[4],
  transition: 'transform 0.3s',
  '&:hover': {
    transform: 'translateY(-5px)',
  },
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: theme.spacing(1),
}));

// Mapeamento de ícones para os cards de resumo
const iconMap: { [key: string]: JSX.Element } = {
  'Total de Enxames': <TrendingUp fontSize="large" color="success" />,
  'Total de Iscas': <EmojiNature fontSize="large" color="warning" />,
  'Total de Vendas': <ShoppingCart fontSize="large" color="primary" />,
  'Total de Doações': <VolunteerActivism fontSize="large" color="info" />,
  'Total de Perdas': <TrendingDown fontSize="large" color="error" />,
  'Total de Capturas': <EmojiPeople fontSize="large" color="secondary" />,
};

// Componente de Card de Resumo
interface ResumoCardProps {
  title: string;
  value: string | number;
  color: string;
}

const ResumoCard: React.FC<ResumoCardProps> = ({ title, value, color }) => {
  return (
    <StyledPaper>
      <IconWrapper>
        {iconMap[title] || <TrendingUp fontSize="large" style={{ color }} />}
      </IconWrapper>
      <Typography variant="subtitle1" color="textSecondary" align="center">
        {title}
      </Typography>
      <Typography variant="h5" align="center" sx={{ fontWeight: 'bold', mt: 1 }}>
        {value}
      </Typography>
    </StyledPaper>
  );
};

const Dashboard: React.FC = () => {
  const { user } = useContext(AuthContext);
  const [dashboardData, setDashboardData] = useState<DashboardResponse | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const response = await api.get('/dashboard', {
          params: {
            userId: user?.id,
          },
        });
        setDashboardData(response.data);
      } catch (error) {
        console.error('Erro ao buscar dados do dashboard:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchDashboardData();
  }, [user]);

  // Labels dos meses em ordem cronológica
  const monthLabels = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
  ];

  if (loading) {
    return (
      <Layout>
        <Container maxWidth="lg" sx={{ mt: 4 }}>
          <Typography variant="h4" gutterBottom>
            Dashboard
          </Typography>
          <Grid container spacing={4}>
            {Array.from(new Array(6)).map((_, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Skeleton variant="rectangular" height={150} />
              </Grid>
            ))}
          </Grid>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 8 }}>
            <CircularProgress color="secondary" size={60} />
          </Box>
        </Container>
      </Layout>
    );
  }

  if (!dashboardData) {
    return (
      <Layout>
        <Container maxWidth="md" sx={{ mt: 4 }}>
          <Typography variant="h5" color="error">
            Dados do dashboard não encontrados.
          </Typography>
        </Container>
      </Layout>
    );
  }

  // Preparar dados para os gráficos

  // Gráfico de Barras Empilhadas: Produção Mensal de Produtos
  const productionData = {
    labels: monthLabels,
    datasets: [
      {
        label: 'Mel (kg)',
        data: monthLabels.map(
          (month) => dashboardData.monthlyData.mel[month]?.totalMel || 0
        ),
        backgroundColor: theme.palette.warning.main,
      },
      {
        label: 'Cera (kg)',
        data: monthLabels.map(
          (month) => dashboardData.monthlyData.cera[month]?.totalCera || 0
        ),
        backgroundColor: theme.palette.secondary.main,
      },
      {
        label: 'Própolis (kg)',
        data: monthLabels.map(
          (month) => dashboardData.monthlyData.propolis[month]?.totalPropolis || 0
        ),
        backgroundColor: theme.palette.success.main,
      },
      {
        label: 'Geleia Real (kg)',
        data: monthLabels.map(
          (month) => dashboardData.monthlyData.geleiaReal[month]?.totalGeleiaReal || 0
        ),
        backgroundColor: theme.palette.error.main,
      },
      {
        label: 'Pólen (kg)',
        data: monthLabels.map(
          (month) => dashboardData.monthlyData.polen[month]?.totalPolen || 0
        ),
        backgroundColor: theme.palette.info.main,
      },
    ],
  };

  // Opções aprimoradas para gráficos de barras
  const productionOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: isMobile ? 'top' as const : 'bottom' as const,
        labels: {
          boxWidth: 20,
          padding: 15,
        },
      },
      tooltip: {
        mode: 'index' as const,
        intersect: false,
      },
    },
    interaction: {
      mode: 'nearest' as const,
      axis: 'x' as const,
      intersect: false,
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
        ticks: {
          color: theme.palette.text.secondary,
        },
      },
      y: {
        stacked: true,
        beginAtZero: true,
        grid: {
          color: theme.palette.grey[200],
        },
        ticks: {
          color: theme.palette.text.secondary,
        },
      },
    },
  };

  // Gráfico de Pizza: Distribuição Total de Produtos Colhidos
  const totalColheitasData = {
    labels: ['Mel', 'Cera', 'Própolis', 'Geleia Real', 'Pólen'],
    datasets: [
      {
        data: [
          dashboardData.totalMel,
          dashboardData.totalCera,
          dashboardData.totalPropolis,
          dashboardData.totalGeleiaReal,
          dashboardData.totalPolen,
        ],
        backgroundColor: [
          theme.palette.warning.main,
          theme.palette.secondary.main,
          theme.palette.success.main,
          theme.palette.error.main,
          theme.palette.info.main,
        ],
        hoverOffset: 6,
      },
    ],
  };

  // Opções aprimoradas para gráfico de pizza
  const pieOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: isMobile ? 'top' as const : 'right' as const,
        labels: {
          boxWidth: 20,
          padding: 15,
        },
      },
      tooltip: {
        callbacks: {
          label: function(context: any) {
            const label = context.label || '';
            const value = context.raw || 0;
            const total = context.chart._metasets[context.datasetIndex].total;
            const percentage = ((value / total) * 100).toFixed(2);
            return `${label}: ${value} kg (${percentage}%)`;
          },
        },
      },
    },
  };

  // Gráfico de Linha: Eventos Mensais (Perdas, Doações, Capturas)
  const eventosLineData = {
    labels: monthLabels,
    datasets: [
      {
        label: 'Perdas',
        data: monthLabels.map(
          (month) => dashboardData.monthlyData.perdas[month]?.totalPerdas || 0
        ),
        fill: true,
        borderColor: theme.palette.error.main,
        backgroundColor: theme.palette.error.light,
        tension: 0.4,
        pointStyle: 'circle',
        pointRadius: 4,
        pointHoverRadius: 6,
      },
      {
        label: 'Doações',
        data: monthLabels.map(
          (month) => dashboardData.monthlyData.doacoes[month]?.totalDoacoes || 0
        ),
        fill: true,
        borderColor: theme.palette.success.main,
        backgroundColor: theme.palette.success.light,
        tension: 0.4,
        pointStyle: 'circle',
        pointRadius: 4,
        pointHoverRadius: 6,
      },
      {
        label: 'Capturas',
        data: monthLabels.map(
          (month) => dashboardData.monthlyData.capturas[month]?.totalCapturas || 0
        ),
        fill: true,
        borderColor: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.light,
        tension: 0.4,
        pointStyle: 'circle',
        pointRadius: 4,
        pointHoverRadius: 6,
      },
    ],
  };

  // Opções aprimoradas para gráfico de linha de eventos
  const eventosLineOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: isMobile ? 'top' as const : 'bottom' as const,
        labels: {
          boxWidth: 20,
          padding: 15,
        },
      },
      tooltip: {
        mode: 'index' as const,
        intersect: false,
      },
    },
    interaction: {
      mode: 'nearest' as const,
      axis: 'x' as const,
      intersect: false,
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: theme.palette.text.secondary,
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          color: theme.palette.grey[200],
        },
        ticks: {
          color: theme.palette.text.secondary,
        },
      },
    },
  };

  // Gráfico de Linha: Evolução das Vendas ao Longo dos Meses
  const vendasLineData = {
    labels: monthLabels,
    datasets: [
      {
        label: 'Vendas (R$)',
        data: monthLabels.map(
          (month) => dashboardData.monthlyData.vendas[month]?.totalVendas || 0
        ),
        fill: true,
        borderColor: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.light,
        tension: 0.4,
        pointStyle: 'circle',
        pointRadius: 5,
        pointHoverRadius: 7,
      },
    ],
  };

  // Opções aprimoradas para gráfico de linha de vendas
  const vendasLineOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function(context: any) {
            const value = context.raw || 0;
            return `Vendas: R$ ${value.toLocaleString('pt-BR')}`;
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: theme.palette.text.secondary,
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          color: theme.palette.grey[200],
        },
        ticks: {
          callback: function(value: any) {
            return `R$ ${value}`;
          },
          color: theme.palette.text.secondary,
        },
      },
    },
  };

  // Cards de Resumo
  const resumoCards = [
    {
      title: 'Total de Enxames',
      value: dashboardData.totalEnxames,
      color: theme.palette.success.main,
    },
    {
      title: 'Total de Iscas',
      value: dashboardData.totalBaits,
      color: theme.palette.warning.main,
    },
    {
      title: 'Total de Vendas',
      value: dashboardData.totalVendas.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }),
      color: theme.palette.primary.main,
    },
    {
      title: 'Total de Doações',
      value: dashboardData.totalDoacoes,
      color: theme.palette.info.main,
    },
    {
      title: 'Total de Perdas',
      value: dashboardData.totalPerdas,
      color: theme.palette.error.main,
    },
    {
      title: 'Total de Capturas',
      value: dashboardData.totalCapturas,
      color: theme.palette.secondary.main,
    },
  ];

  return (
    <Layout>
      <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
 

        {/* Resumo dos Totais */}
        <Grid container spacing={3}>
          {resumoCards.map((card, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <ResumoCard title={card.title} value={card.value} color={card.color} />
            </Grid>
          ))}
        </Grid>

        {/* Gráficos */}
        <Grid container spacing={3} sx={{ mt: 2 }}>
          {/* Gráfico de Barras Empilhadas: Produção Mensal de Produtos */}
          <Grid item xs={12} lg={6}>
            <StyledPaper sx={{ height: '100%' }}>
              <Typography variant="h6" gutterBottom>
                Produção Mensal de Produtos
              </Typography>
              <Box sx={{ height: 300 }}>
                <Bar data={productionData} options={productionOptions} />
              </Box>
            </StyledPaper>
          </Grid>

          {/* Gráfico de Linha: Evolução das Vendas */}
          <Grid item xs={12} lg={6}>
            <StyledPaper sx={{ height: '100%' }}>
              <Typography variant="h6" gutterBottom>
                Evolução das Vendas
              </Typography>
              <Box sx={{ height: 300 }}>
                <Line data={vendasLineData} options={vendasLineOptions} />
              </Box>
            </StyledPaper>
          </Grid>

          {/* Gráfico de Pizza: Distribuição Total de Produtos Colhidos */}
          <Grid item xs={12} md={6}>
            <StyledPaper sx={{ height: 400 }}>
              <Typography variant="h6" gutterBottom>
                Distribuição Total de Produtos Colhidos
              </Typography>
              <Box sx={{ height: '90%' }}>
                <Pie data={totalColheitasData} options={pieOptions} />
              </Box>
            </StyledPaper>
          </Grid>

          {/* Gráfico de Linha: Eventos Mensais */}
          <Grid item xs={12} md={6}>
            <StyledPaper sx={{ height: 400 }}>
              <Typography variant="h6" gutterBottom>
                Eventos Mensais (Perdas, Doações, Capturas)
              </Typography>
              <Box sx={{ height: '90%' }}>
                <Line data={eventosLineData} options={eventosLineOptions} />
              </Box>
            </StyledPaper>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
};

export default Dashboard;
