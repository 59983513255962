import React, { useEffect, useState, useContext } from 'react';
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
  Box,
  CircularProgress,
  CardHeader,
  Avatar,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import api from '../services/api';
import { AuthContext } from '../context/AuthContext';
import Layout from './Layout';
import { styled } from '@mui/material/styles';
import HiveIcon from '@mui/icons-material/Hive';

interface Bait {
  id: number;
  identificador: string;
  descricao?: string;
  photoUrl?: string;
  atrativo: string;
  localizacao: string;
  createddate: string;
  material: string;
}

// Custom styled components
const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: 345,
  borderRadius: theme.spacing(2),
  transition: 'transform 0.3s, box-shadow 0.3s',
  '&:hover': {
    transform: 'translateY(-8px)',
    boxShadow: theme.shadows[6],
  },
  backgroundColor: '#FFF8E1', // Light honey color
}));

const HeaderBoxFull = styled(Box)(({ theme }) => ({
  backgroundColor: '#FFB300', // Honey yellow
  padding: theme.spacing(4, 0),
  width: '100vw',  // Occupying 100% of viewport width
  marginLeft: 'calc(-50vw + 50%)',  // Align to the left of viewport
  marginBottom: theme.spacing(4),
}));

const BaitList: React.FC = () => {
  const { user } = useContext(AuthContext);
  const [baits, setBaits] = useState<Bait[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBaits = async () => {
      try {
        const response = await api.get(`/bait/user/${user?.id}`);
        setBaits(response.data);
      } catch (error) {
        console.error('Error fetching baits:', error);
      } finally {
        setLoading(false);
      }
    };

    if (user?.id) {
      fetchBaits();
    } else {
      setLoading(false);
    }
  }, [user]);

  const handleItemClick = (id: number) => {
    navigate(`/baits/${id}`);
  };

  return (
    <Layout>
      {/* <HeaderBoxFull>
        <Container>
          <Typography variant="h3" component="h1" color="#fff" align="center">
            Gestão de Iscas
          </Typography>
          <Typography variant="subtitle1" color="#fff" align="center" sx={{ mt: 1 }}>
            Organize e monitore suas iscas com facilidade
          </Typography>
        </Container>
      </HeaderBoxFull> */}
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 8 }}>
            <CircularProgress color="secondary" size={60} />
          </Box>
        ) : baits.length === 0 ? (
          <Typography variant="h6" align="center" color="text.secondary">
            Nenhuma isca encontrada. Adicione sua primeira isca!
          </Typography>
        ) : (
          <Grid container spacing={4}>
            {baits.map((bait) => (
              <Grid item key={bait.id} xs={12} sm={6} md={4}>
                <StyledCard>
                  <CardActionArea onClick={() => handleItemClick(bait.id)}>
                    {bait.photoUrl ? (
                      <CardMedia
                        component="img"
                        height="180"
                        image={bait.photoUrl}
                        alt={bait.identificador}
                      />
                    ) : (
                      <Box
                        sx={{
                          height: 180,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          backgroundColor: '#FFE082', // Lighter honey color
                        }}
                      >
                        <HiveIcon /> {/* Using Hive icon */}
                      </Box>
                    )}
                    <CardHeader
                      avatar={
                        <Avatar sx={{ bgcolor: '#FFA000' }}>
                          {bait.identificador.charAt(0).toUpperCase()}
                        </Avatar>
                      }
                      title={
                        <Typography variant="h6" component="div">
                          {bait.identificador}
                        </Typography>
                      }
                      sx={{
                        backgroundColor: '#FFF3E0', // Very light honey
                      }}
                    />
                    <CardContent>
                      <Typography variant="body2" color="text.secondary" gutterBottom>
                        {bait.descricao
                          ? bait.descricao.length > 100
                            ? `${bait.descricao.substring(0, 100)}...`
                            : bait.descricao
                          : 'Sem descrição disponível.'}
                      </Typography>
                      <Typography variant="body2" color="text.primary">
                        <strong>Material:</strong> {bait.material}
                      </Typography>
                      <Typography variant="body2" color="text.primary">
                        <strong>Atrativo:</strong> {bait.atrativo}
                      </Typography>
                      <Typography variant="body2" color="text.primary">
                        <strong>Localização:</strong> {bait.localizacao}
                      </Typography>
                      <Typography variant="body2" color="text.primary">
                        <strong>Data de Criação:</strong>{' '}
                        {new Date(bait.createddate).toLocaleDateString()}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </StyledCard>
              </Grid>
            ))}
          </Grid>
        )}
      </Container>
    </Layout>
  );
};

export default BaitList;
