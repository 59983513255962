// src/components/EnxameMap.tsx

import React, { useEffect, useState, useContext, useCallback } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { AuthContext } from '../context/AuthContext';
import { Enxame } from '../interfaces/Enxame';
import api from '../services/api';
import { CircularProgress, Box, Button } from '@mui/material';
import { toast } from 'react-toastify';
import L, { Icon } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { useNavigate } from 'react-router-dom';

// Corrigir problemas com ícones padrão do Leaflet
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

// Importar ícone personalizado corretamente
import beehiveIcon from '../assets/beehive.png';

// Remover URLs padrão dos ícones
delete (L.Icon.Default.prototype as any)._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: markerIcon2x,
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
});

// Definir o ícone personalizado com tamanho reduzido
const customBeehiveIcon = new L.Icon({
  iconUrl: beehiveIcon,
  iconSize: [25, 25], // Tamanho reduzido
  iconAnchor: [12, 25], // Ajustado para o novo tamanho
  popupAnchor: [0, -25], // Ajustado para o novo tamanho
});

const defaultCenter: L.LatLngExpression = [37.4219983, -122.084]; // Posição padrão caso não haja enxames

const EnxameMap: React.FC = () => {
  const { user } = useContext(AuthContext);
  const [enxames, setEnxames] = useState<Enxame[]>([]);
  const [currentPosition, setCurrentPosition] = useState<L.LatLngExpression | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [averagePosition, setAveragePosition] = useState<L.LatLngExpression | null>(null);
  const navigate = useNavigate(); // Inicializar useNavigate

  // Função para calcular a média das coordenadas
  const calculateAveragePosition = (enxamesList: Enxame[]) => {
    console.log('Calculando a posição média para', enxamesList.length, 'enxames.');
    if (enxamesList.length === 0) {
      console.warn('Lista de enxames vazia. Posição média não pode ser calculada.');
      return null;
    }

    const total = enxamesList.reduce(
      (acc, enxame) => {
        const [latStr, lngStr] = enxame.localizacao.split(',');
        const lat = parseFloat(latStr.trim());
        const lng = parseFloat(lngStr.trim());
        console.log(`Enxame ID: ${enxame.id}, Latitude: ${lat}, Longitude: ${lng}`);
        return {
          lat: acc.lat + lat,
          lng: acc.lng + lng,
        };
      },
      { lat: 0, lng: 0 }
    );

    const avgLat = total.lat / enxamesList.length;
    const avgLng = total.lng / enxamesList.length;

    console.log(`Posição média calculada: [${avgLat}, ${avgLng}]`);
    return [avgLat, avgLng] as L.LatLngExpression;
  };

  // Função para buscar os enxames
  const fetchEnxames = useCallback(
    async (latitude?: number, longitude?: number) => {
      console.log('Iniciando a busca por enxames...');
      if (!user) {
        console.error('Usuário não autenticado. Não é possível buscar enxames.');
        toast.error('Usuário não autenticado.');
        setLoading(false);
        return;
      }

      console.log('ID do usuário autenticado:', user.id);

      try {
        const response = await api.get(`/enxame/user/${user.id}`);
        console.log('Resposta da API recebida:', response);
        const fetchedEnxames: Enxame[] = response.data;
        console.log('Enxames obtidos da API:', fetchedEnxames);

        // Remover a filtragem por distância
        setEnxames(fetchedEnxames);
        console.log('Todos os enxames foram definidos para exibição.');

        setLoading(false);
      } catch (error: any) {
        console.error('Erro ao buscar enxames:', error);
        if (error.response) {
          console.error('Detalhes do erro da API:', error.response.data);
        }
        toast.error('Erro ao buscar enxames.');
        setLoading(false);
      }
    },
    [user]
  );

  // Obter a posição do usuário e buscar enxames
  useEffect(() => {
    console.log('UseEffect: Verificando se o usuário está autenticado para obter a localização.');
    if (user) {
      console.log('Usuário autenticado. Tentando obter a localização do navegador...');
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            console.log('Localização do usuário obtida:', position);
            const pos: L.LatLngExpression = [position.coords.latitude, position.coords.longitude];
            setCurrentPosition(pos);
            fetchEnxames(position.coords.latitude, position.coords.longitude);
          },
          (error) => {
            console.error('Erro ao obter localização:', error);
            toast.error('Erro ao obter sua localização.');
            fetchEnxames(); // Buscar sem filtrar por localização
          }
        );
      } else {
        console.error('Geolocalização não é suportada por este navegador.');
        toast.error('Geolocalização não é suportada por este navegador.');
        fetchEnxames(); // Buscar sem filtrar por localização
      }
    } else {
      console.warn('Usuário não está autenticado. Enxames não serão buscados.');
      setLoading(false);
    }
  }, [fetchEnxames, user]);

  // Atualizar a posição média sempre que os enxames mudarem
  useEffect(() => {
    console.log('UseEffect: Os enxames foram atualizados. Recalculando a posição média...');
    const avgPos = calculateAveragePosition(enxames);
    console.log('Posição média atualizada:', avgPos);
    setAveragePosition(avgPos);
  }, [enxames]);

  if (loading) {
    console.log('Componente está carregando. Exibindo indicador de carregamento.');
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  console.log('Renderizando o MapContainer com', enxames.length, 'enxames.');
  return (
    <MapContainer
      style={{ width: '100%', height: '600px' }}
      center={averagePosition || defaultCenter}
      zoom={14}
      scrollWheelZoom={true}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors'
        url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
      />
      {currentPosition && (
        <Marker position={currentPosition}>
          <Popup>Sua localização</Popup>
        </Marker>
      )}
      {enxames.map((enxame) => {
        const [latStr, lngStr] = enxame.localizacao.split(',');
        const lat = parseFloat(latStr.trim());
        const lng = parseFloat(lngStr.trim());
        console.log(`Renderizando marcador para o enxame ID: ${enxame.id} em [${lat}, ${lng}]`);
        return (
          <Marker
            key={enxame.id}
            position={[lat, lng]}
            icon={customBeehiveIcon} // Usar o ícone com tamanho reduzido
          >
            <Popup>
              <div>
                <h3>Isca: {enxame.identificador}</h3>
                <p><strong>Descrição:</strong> {enxame.descricao || 'N/A'}</p>
                <p><strong>Material:</strong> {enxame.modeloCaixa}</p>
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={() => navigate(`/enxames/${enxame.id}`)} // Usar useNavigate para redirecionar
                  sx={{ mt: 1 }}
                >
                  Ver Detalhes
                </Button>
              </div>
            </Popup>
          </Marker>
        );
      })}
    </MapContainer>
  );
};

export default React.memo(EnxameMap);
