// src/services/tokenService.ts
let token: string | null = localStorage.getItem('token');
let refreshToken: string | null = localStorage.getItem('refreshToken');

const setToken = (newToken: string | null) => {
  token = newToken;
  if (newToken) {
    localStorage.setItem('token', newToken);
  } else {
    localStorage.removeItem('token');
  }
};

const getToken = () => token;

const setRefreshToken = (newRefreshToken: string | null) => {
  refreshToken = newRefreshToken;
  if (newRefreshToken) {
    localStorage.setItem('refreshToken', newRefreshToken);
  } else {
    localStorage.removeItem('refreshToken');
  }
};

const getRefreshToken = () => refreshToken;

export default {
  setToken,
  getToken,
  setRefreshToken,
  getRefreshToken,
};
