import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Box,
  CircularProgress,
  Divider,
  Card,
  CardContent,
  CardMedia,
  Grid,
  useTheme,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import api from '../services/api';
import Layout from './Layout';
import HiveIcon from '@mui/icons-material/Hive';
import LocalFloristIcon from '@mui/icons-material/LocalFlorist';
import NaturePeopleIcon from '@mui/icons-material/NaturePeople';
import BuildIcon from '@mui/icons-material/Build';
import EmojiNatureIcon from '@mui/icons-material/EmojiNature';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import { styled } from '@mui/material/styles';
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

// Estilização personalizada do card utilizando o tema
const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: '100%',
  borderRadius: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[5],
  overflow: 'hidden',
  marginTop: theme.spacing(4),
}));

// Interfaces
interface BaseAction {
  id: number;
  type: 'Alimentação' | 'Manejo' | 'Revisão' | 'Divisão' | 'Transferência';
  createddate: string;
  observacao?: string;
  enxameId?: number;
  baitId?: number;
}

interface AlimentacaoAction extends BaseAction {
  type: 'Alimentação';
  tipoAlimento: string;
}

interface ManejoAction extends BaseAction {
  type: 'Manejo';
}

interface RevisaoAction extends BaseAction {
  type: 'Revisão';
  rainhaEncontrada: boolean;
  postura: boolean;
  realeiras: boolean;
  pestes: boolean;
  reservaMel: string;
  reservaPolen: string;
}

interface DivisaoAction extends BaseAction {
  type: 'Divisão';
}

interface TransferenciaAction extends BaseAction {
  type: 'Transferência';
  modeloCaixa: string;
}

type Action =
  | AlimentacaoAction
  | ManejoAction
  | RevisaoAction
  | DivisaoAction
  | TransferenciaAction;

interface Enxame {
  id: number;
  identificador: string;
  descricao?: string;
  manejo?: ManejoAction[];
  alimentacao?: AlimentacaoAction[];
  revisao?: RevisaoAction[];
  divisao?: DivisaoAction[];
  transferencia?: TransferenciaAction[];
  photoUrl?: string;
  especie: string;
  localizacao: string;
  createddate: string;
}

const EnxameDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [enxame, setEnxame] = useState<Enxame | null>(null);
  const [actions, setActions] = useState<Action[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const theme = useTheme();

  useEffect(() => {
    const fetchEnxameDetails = async () => {
      try {
        const response = await api.get(`/enxame/swarm/${id}`);
        setEnxame(response.data);

        const allActions: Action[] = [];

        response.data.manejo?.forEach((item: ManejoAction) => {
          allActions.push({ ...item, type: 'Manejo' });
        });
        response.data.alimentacao?.forEach((item: AlimentacaoAction) => {
          allActions.push({ ...item, type: 'Alimentação' });
        });
        response.data.revisao?.forEach((item: RevisaoAction) => {
          allActions.push({ ...item, type: 'Revisão' });
        });
        response.data.divisao?.forEach((item: DivisaoAction) => {
          allActions.push({ ...item, type: 'Divisão' });
        });
        response.data.transferencia?.forEach((item: TransferenciaAction) => {
          allActions.push({ ...item, type: 'Transferência' });
        });

        allActions.sort(
          (a, b) =>
            new Date(b.createddate).getTime() - new Date(a.createddate).getTime()
        );
        setActions(allActions);
      } catch (error) {
        console.error('Erro ao buscar detalhes do enxame:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchEnxameDetails();
  }, [id]);

  const getIcon = (type: string) => {
    switch (type) {
      case 'Alimentação':
        return <EmojiNatureIcon />;
      case 'Manejo':
        return <BuildIcon />;
      case 'Revisão':
        return <LocalFloristIcon />;
      case 'Divisão':
        return <NaturePeopleIcon />;
      case 'Transferência':
        return <TransferWithinAStationIcon />;
      default:
        return <HiveIcon />;
    }
  };

  const renderActionDetails = (action: Action) => {
    switch (action.type) {
      case 'Alimentação':
        return (
          <>
            <Typography variant="body2" color="text.primary">
              Tipo de Alimento: {action.tipoAlimento}
            </Typography>
            {action.observacao && (
              <Typography variant="body2" color="text.secondary">
                Observação: {action.observacao}
              </Typography>
            )}
          </>
        );
      case 'Manejo':
        return (
          <>
            {action.observacao && (
              <Typography variant="body2" color="text.secondary">
                Observação: {action.observacao}
              </Typography>
            )}
          </>
        );
      case 'Revisão':
        return (
          <>
            <Typography variant="body2" color="text.primary">
              Rainha Encontrada: {action.rainhaEncontrada ? 'Sim' : 'Não'}
            </Typography>
            <Typography variant="body2" color="text.primary">
              Postura: {action.postura ? 'Sim' : 'Não'}
            </Typography>
            <Typography variant="body2" color="text.primary">
              Realeiras: {action.realeiras ? 'Sim' : 'Não'}
            </Typography>
            <Typography variant="body2" color="text.primary">
              Pestes: {action.pestes ? 'Sim' : 'Não'}
            </Typography>
            <Typography variant="body2" color="text.primary">
              Reserva de Mel: {action.reservaMel}
            </Typography>
            <Typography variant="body2" color="text.primary">
              Reserva de Pólen: {action.reservaPolen}
            </Typography>
            {action.observacao && (
              <Typography variant="body2" color="text.secondary">
                Observação: {action.observacao}
              </Typography>
            )}
          </>
        );
      case 'Divisão':
        return (
          <>
            {action.observacao && (
              <Typography variant="body2" color="text.secondary">
                Observação: {action.observacao}
              </Typography>
            )}
          </>
        );
      case 'Transferência':
        return (
          <>
            <Typography variant="body2" color="text.primary">
              Modelo da Caixa: {action.modeloCaixa}
            </Typography>
            {action.observacao && (
              <Typography variant="body2" color="text.secondary">
                Observação: {action.observacao}
              </Typography>
            )}
          </>
        );
      default:
        return null;
    }
  };

  if (loading) {
    return (
      <Layout>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress color="secondary" />
        </Box>
      </Layout>
    );
  }

  if (!enxame) {
    return (
      <Layout>
        <Container maxWidth="lg" sx={{ mt: 4 }}>
          <Typography variant="h5">Enxame não encontrado.</Typography>
        </Container>
      </Layout>
    );
  }

  return (
    <Layout>
      <Container maxWidth="md">
        <StyledCard>
          <CardMedia
            component="img"
            height="400"
            image={enxame.photoUrl || '/assets/default-enxame.jpg'}
            alt={enxame.identificador}
            sx={{ objectFit: 'cover' }}
          />
          <CardContent>
            <Typography variant="h3" gutterBottom>
              {enxame.identificador}
            </Typography>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" gutterBottom>
                  <strong>Espécie:</strong> {enxame.especie}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <strong>Localização:</strong> {enxame.localizacao}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" gutterBottom>
                  <strong>Data de Criação:</strong>{' '}
                  {new Date(enxame.createddate).toLocaleDateString('pt-BR', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  })}
                </Typography>
                {enxame.descricao && (
                  <Typography variant="body1" gutterBottom>
                    <strong>Descrição:</strong> {enxame.descricao}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </StyledCard>

        <Box sx={{ mt: 8 }}>
          <Typography variant="h4" gutterBottom>
            Linha do Tempo
          </Typography>
          <Divider sx={{ mb: 4 }} />
          <VerticalTimeline>
            {actions.map((action) => (
              <VerticalTimelineElement
                key={`${action.type}-${action.id}`}
                date={new Date(action.createddate).toLocaleDateString('pt-BR', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                })}
                iconStyle={{
                  background: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                }}
                icon={getIcon(action.type)}
                contentStyle={{
                  background: theme.palette.background.paper,
                  color: theme.palette.text.primary,
                  borderTop: `3px solid ${theme.palette.primary.main}`,
                  boxShadow: theme.shadows[3],
                }}
                contentArrowStyle={{
                  borderRight: `7px solid ${theme.palette.primary.main}`,
                }}
              >
                <Typography variant="h6" gutterBottom>
                  {action.type}
                </Typography>
                {renderActionDetails(action)}
              </VerticalTimelineElement>
            ))}
          </VerticalTimeline>
        </Box>
      </Container>
    </Layout>
  );
};

export default EnxameDetails;
