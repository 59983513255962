// src/components/SendEmail.tsx
import React, { useState, useEffect, useContext } from 'react';
import { Container, Typography, TextField, Button, Box, CircularProgress, Alert } from '@mui/material';
import api from '../services/api';
import { AuthContext } from '../context/AuthContext';

const SendEmail: React.FC = () => {
  const { user } = useContext(AuthContext);
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [recipients, setRecipients] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  // Função para buscar todos os usuários autorizados
  const fetchAllUsersEmails = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await api.get('/users/all');
      const emails = response.data
        .filter((user: { status: string; planType: string }) => 
          user.status === 'active' 
        )
        .map((user: { email: string }) => user.email)
        .join(', ');
      setRecipients(emails);
    } catch (err) {
      setError('Falha ao carregar e-mails dos usuários.');
    } finally {
      setLoading(false);
    }
  };

  const handleSendEmail = async () => {
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const response = await api.post('/email/send', {
        subject,
        body,
        recipients: recipients.split(',').map(email => email.trim()),
      });
      
      setSuccess(response.data.message);
      setSubject('');
      setBody('');
      setRecipients('');
    } catch (err) {
      setError('Falha ao enviar e-mail. Por favor, tente novamente.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user && ['kennyldoofc@gmail.com', 'kennildodev@gmail.com'].includes(user.email)) {
      fetchAllUsersEmails();
    }
  }, [user]);

  return (
    <Container maxWidth="sm" sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        Enviar E-mails
      </Typography>
      <Box component="form" noValidate autoComplete="off">
        <TextField
          label="Assunto"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          fullWidth
          margin="normal"
          variant="outlined"
        />
        <TextField
          label="Corpo do E-mail"
          value={body}
          onChange={(e) => setBody(e.target.value)}
          fullWidth
          multiline
          rows={6}
          margin="normal"
          variant="outlined"
        />
        <TextField
          label="Destinatários (separados por vírgula)"
          value={recipients}
          onChange={(e) => setRecipients(e.target.value)}
          fullWidth
          margin="normal"
          variant="outlined"
        />
        <Box sx={{ mt: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSendEmail}
            disabled={loading || !subject || !body || !recipients}
            fullWidth
          >
            {loading ? <CircularProgress size={24} /> : 'Enviar'}
          </Button>
        </Box>
      </Box>
      {success && <Alert severity="success" sx={{ mt: 2 }}>{success}</Alert>}
      {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}
    </Container>
  );
};

export default SendEmail;
