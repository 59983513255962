import React, { useEffect } from 'react';

const RedirectToPlayStore: React.FC = () => {
  useEffect(() => {
    window.location.href = 'https://play.google.com/store/apps/details?id=com.pollen.app';
  }, []);

  return null;
};

export default RedirectToPlayStore;
