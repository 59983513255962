// src/components/PrivacyPolicy.tsx
import React from 'react';
import { Container, Typography, Box, Divider } from '@mui/material';
import { Link as MuiLink } from '@mui/material';
import Layout from './Layout';

const PrivacyPolicy: React.FC = () => {
  return (
    <Layout>
    <Container maxWidth="md">
      <Box sx={{ my: 6 }}>
        <Typography variant="h3" component="h1" gutterBottom>
          Política de Privacidade
        </Typography>

        <Typography variant="body2" color="textSecondary" paragraph>
          Última atualização: 28 de setembro de 2024
        </Typography>

        <Divider sx={{ my: 3 }} />

        <Typography variant="body1" paragraph>
          A sua privacidade é importante para nós. Esta Política de Privacidade descreve como coletamos, usamos e protegemos suas informações ao utilizar o aplicativo Pollen.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          1. Informações Coletadas
        </Typography>
        <Typography variant="body1" paragraph>
          Ao usar o Pollen, podemos coletar as seguintes informações:
        </Typography>
        <ul>
          <li>Dados pessoais: Nome, e-mail, e outros dados fornecidos ao criar uma conta.</li>
          <li>Localização: Coletamos informações sobre a sua localização para melhorar a funcionalidade do aplicativo, como monitoramento de colmeias e geolocalização de meliponários.</li>
          <li>Fotos e Imagens: O aplicativo tem acesso à sua galeria e câmera para permitir o upload de fotos relacionadas às suas atividades apícolas.</li>
          <li>Dados de Pagamento: Informações de pagamento para a gestão das assinaturas mensais (não armazenamos diretamente os dados do cartão de crédito, eles são gerenciados pelo processador de pagamentos).</li>
        </ul>

        <Typography variant="h5" component="h2" gutterBottom>
          2. Como Usamos Suas Informações
        </Typography>
        <Typography variant="body1" paragraph>
          As informações coletadas são utilizadas para:
        </Typography>
        <ul>
          <li>Gerenciar e melhorar a experiência de uso do aplicativo.</li>
          <li>Fornecer suporte técnico e operacional.</li>
          <li>Processar pagamentos de assinaturas.</li>
          <li>Personalizar conteúdos e serviços com base na sua localização e dados fornecidos.</li>
        </ul>

        <Typography variant="h5" component="h2" gutterBottom>
          3. Compartilhamento de Informações
        </Typography>
        <Typography variant="body1" paragraph>
          Nós não vendemos ou compartilhamos suas informações pessoais com terceiros, exceto:
        </Typography>
        <ul>
          <li>Serviços de Terceiros: Utilizamos provedores externos para processar pagamentos, armazenamento de dados e outros serviços técnicos.</li>
          <li>Exigências Legais: Podemos compartilhar informações quando exigido por lei ou por ordem judicial.</li>
        </ul>

        <Typography variant="h5" component="h2" gutterBottom>
          4. Segurança dos Dados
        </Typography>
        <Typography variant="body1" paragraph>
          Adotamos medidas técnicas e organizacionais para proteger suas informações contra perda, uso indevido e acesso não autorizado. No entanto, nenhum sistema é completamente seguro, e não podemos garantir a segurança absoluta de suas informações.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          5. Direitos do Usuário
        </Typography>
        <Typography variant="body1" paragraph>
          Você tem o direito de:
        </Typography>
        <ul>
          <li>Acessar, corrigir ou excluir suas informações pessoais.</li>
          <li>Revogar o consentimento para o uso dos dados, caso não deseje mais utilizar os serviços do aplicativo.</li>
          <li>Solicitar a portabilidade dos seus dados para outros serviços.</li>
        </ul>
        <Typography variant="body1" paragraph>
          Para exercer qualquer um desses direitos, entre em contato conosco pelo e-mail <MuiLink href="mailto:team@pollen.blog">team@pollen.blog</MuiLink>.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          6. Cookies e Tecnologias de Rastreamento
        </Typography>
        <Typography variant="body1" paragraph>
          O aplicativo pode utilizar cookies e tecnologias semelhantes para melhorar a experiência do usuário e coletar informações sobre como o aplicativo é utilizado. Esses dados podem incluir informações sobre o dispositivo, o navegador e o tempo de uso.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          7. Alterações na Política de Privacidade
        </Typography>
        <Typography variant="body1" paragraph>
          Reservamo-nos o direito de modificar esta Política de Privacidade a qualquer momento. As alterações serão notificadas por meio do aplicativo ou por e-mail. O uso contínuo do aplicativo após a publicação das alterações constitui sua aceitação da nova Política de Privacidade.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          8. Legislação Aplicável e Foro
        </Typography>
        <Typography variant="body1" paragraph>
          Esta Política de Privacidade será regida e interpretada de acordo com as leis brasileiras. Fica eleito o foro da comarca de Chapecó, SC para dirimir quaisquer litígios decorrentes desta Política, com renúncia a qualquer outro, por mais privilegiado que seja.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          9. Contato
        </Typography>
        <Typography variant="body1" paragraph>
          Se você tiver dúvidas sobre esta Política de Privacidade, entre em contato conosco em: <MuiLink href="mailto:team@pollen.blog">team@pollen.blog</MuiLink>.
        </Typography>
      </Box>
    </Container>
    </Layout>
  );
};

export default PrivacyPolicy;
