// src/context/AuthContext.tsx
import React, { createContext, useState, useEffect, ReactNode } from 'react';
import tokenService from '../services/tokenService';

interface User {
  id: number;
  email: string;
  planType: string;
}

interface AuthContextType {
  token: string | null;
  refreshToken: string | null;
  user: User | null;
  setToken: (token: string | null) => void;
  setRefreshToken: (refreshToken: string | null) => void;
  setUser: (user: User | null) => void;
  logout: () => void;
}

export const AuthContext = createContext<AuthContextType>({
  token: null,
  refreshToken: null,
  user: null,
  setToken: () => {},
  setRefreshToken: () => {},
  setUser: () => {},
  logout: () => {},
});

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [token, setTokenState] = useState<string | null>(tokenService.getToken());
  const [refreshToken, setRefreshTokenState] = useState<string | null>(tokenService.getRefreshToken());
  const [user, setUser] = useState<User | null>(() => {
    const userData = localStorage.getItem('user');
    return userData ? JSON.parse(userData) : null;
  });

  const setToken = (newToken: string | null) => {
    tokenService.setToken(newToken);
    setTokenState(newToken);
  };

  const setRefreshToken = (newRefreshToken: string | null) => {
    tokenService.setRefreshToken(newRefreshToken);
    setRefreshTokenState(newRefreshToken);
  };

  useEffect(() => {
    if (user) {
      localStorage.setItem('user', JSON.stringify(user));
    } else {
      localStorage.removeItem('user');
    }
  }, [user]);

// src/context/AuthContext.tsx (within AuthProvider)
const logout = () => {
  setToken(null);
  setRefreshToken(null);
  setUser(null);
  localStorage.removeItem('user');
};


  return (
    <AuthContext.Provider value={{ token, refreshToken, user, setToken, setRefreshToken, setUser, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
