// src/components/Layout.tsx
import React, { ReactNode } from 'react';
import { Box, Toolbar } from '@mui/material';
import NavigationBar from './NavigationBar';

interface LayoutProps {
  children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <>
      <NavigationBar />
      {/* Espaçador equivalente à altura da Toolbar do AppBar */}
      <Toolbar />
      <Box sx={{ padding: 0 }}>{children}</Box>
    </>
  );
};

export default Layout;
