import React, { useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Box,
  CircularProgress,
  Divider,
  Card,
  CardContent,
  CardMedia,
  Grid,
  useTheme,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import api from '../services/api';
import Layout from './Layout';
import HiveIcon from '@mui/icons-material/Hive';
import LocalFloristIcon from '@mui/icons-material/LocalFlorist';
import NaturePeopleIcon from '@mui/icons-material/NaturePeople';
import BugReportIcon from '@mui/icons-material/BugReport';
import EmojiNatureIcon from '@mui/icons-material/EmojiNature';
import { styled } from '@mui/material/styles';
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

// Estilização personalizada do card utilizando o tema
const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: '100%',
  borderRadius: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[5],
  overflow: 'hidden',
  marginTop: theme.spacing(4),
}));

// Interfaces (mantêm-se as mesmas)
interface BaseAction {
  id: number;
  type: 'Alimentação' | 'Manejo' | 'Revisão' | 'Atrativo' | 'Captura';
  createddate: string;
  observacao?: string;
  baitId?: number;
}

interface AlimentacaoAction extends BaseAction {
  type: 'Alimentação';
  tipoAlimento: string;
}

interface ManejoAction extends BaseAction {
  type: 'Manejo';
}

interface RevisaoAction extends BaseAction {
  type: 'Revisão';
  rainhaEncontrada: boolean;
  postura: boolean;
  realeiras: boolean;
  pestes: boolean;
  reservaMel: string;
  reservaPolen: string;
}

interface AtrativoAction extends BaseAction {
  type: 'Atrativo';
  tipoAtrativo: string;
}

interface CapturaAction extends BaseAction {
  type: 'Captura';
}

type Action =
  | AlimentacaoAction
  | ManejoAction
  | RevisaoAction
  | AtrativoAction
  | CapturaAction;

interface Bait {
  id: number;
  identificador: string;
  descricao?: string;
  manejo?: ManejoAction[];
  alimentacao?: AlimentacaoAction[];
  revisao?: RevisaoAction[];
  atrativoStatus?: AtrativoAction[];
  captures?: CapturaAction[];
  photoUrl?: string;
  material: string;
  atrativo: string;
  localizacao: string;
  createddate: string;
}

const BaitDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [bait, setBait] = useState<Bait | null>(null);
  const [actions, setActions] = useState<Action[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const theme = useTheme();

  useEffect(() => {
    const fetchBaitDetails = async () => {
      try {
        const response = await api.get(`/bait/single/${id}`);
        setBait(response.data);

        const allActions: Action[] = [];
        response.data.manejo?.forEach((item: ManejoAction) =>
          allActions.push({ ...item, type: 'Manejo' })
        );
        response.data.alimentacao?.forEach((item: AlimentacaoAction) =>
          allActions.push({ ...item, type: 'Alimentação' })
        );
        response.data.revisao?.forEach((item: RevisaoAction) =>
          allActions.push({ ...item, type: 'Revisão' })
        );
        response.data.atrativoStatus?.forEach((item: AtrativoAction) =>
          allActions.push({ ...item, type: 'Atrativo' })
        );
        response.data.captures?.forEach((item: CapturaAction) =>
          allActions.push({ ...item, type: 'Captura' })
        );

        allActions.sort(
          (a, b) =>
            new Date(b.createddate).getTime() - new Date(a.createddate).getTime()
        );
        setActions(allActions);
      } catch (error) {
        console.error('Erro ao buscar detalhes da isca:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchBaitDetails();
  }, [id]);

  const getIcon = (type: Action['type']) => {
    switch (type) {
      case 'Captura':
        return <HiveIcon />;
      case 'Manejo':
        return <NaturePeopleIcon />;
      case 'Revisão':
        return <LocalFloristIcon />;
      case 'Atrativo':
        return <BugReportIcon />;
      case 'Alimentação':
        return <EmojiNatureIcon />;
      default:
        return <HiveIcon />;
    }
  };

  if (loading) {
    return (
      <Layout>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress color="secondary" />
        </Box>
      </Layout>
    );
  }

  if (!bait) {
    return (
      <Layout>
        <Container maxWidth="lg" sx={{ mt: 4 }}>
          <Typography variant="h5">Isca não encontrada.</Typography>
        </Container>
      </Layout>
    );
  }

  return (
    <Layout>
      <Container maxWidth="md">
        <StyledCard>
          <CardMedia
            component="img"
            height="400"
            image={bait.photoUrl || '/assets/default-bait.jpg'}
            alt={bait.identificador}
            sx={{ objectFit: 'cover' }}
          />
          <CardContent>
            <Typography variant="h3" gutterBottom>
              {bait.identificador}
            </Typography>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" gutterBottom>
                  <strong>Material:</strong> {bait.material}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <strong>Atrativo:</strong> {bait.atrativo}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <strong>Localização:</strong> {bait.localizacao}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" gutterBottom>
                  <strong>Data de Criação:</strong>{' '}
                  {new Date(bait.createddate).toLocaleDateString('pt-BR', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                  })}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <strong>Descrição:</strong> {bait.descricao || 'Nenhuma descrição'}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </StyledCard>

        <Box sx={{ mt: 8 }}>
          <Typography variant="h4" gutterBottom>
            Linha do Tempo
          </Typography>
          <Divider sx={{ mb: 4 }} />
          <VerticalTimeline>
            {actions.map((action) => (
              <VerticalTimelineElement
                key={`${action.type}-${action.id}`}
                date={new Date(action.createddate).toLocaleDateString('pt-BR', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit',
                })}
                iconStyle={{
                  background: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                }}
                icon={getIcon(action.type)}
                contentStyle={{
                  background: theme.palette.background.paper,
                  color: theme.palette.text.primary,
                  borderTop: `3px solid ${theme.palette.primary.main}`,
                  boxShadow: theme.shadows[3],
                }}
                contentArrowStyle={{
                  borderRight: `7px solid ${theme.palette.primary.main}`,
                }}
              >
                <Typography variant="h6" gutterBottom>
                  {action.type}
                </Typography>
                <Typography variant="body2">
                  {action.observacao || 'Nenhuma observação'}
                </Typography>
                {/* Adicione mais detalhes específicos da ação, se necessário */}
              </VerticalTimelineElement>
            ))}
          </VerticalTimeline>
        </Box>
      </Container>
    </Layout>
  );
};

export default BaitDetails;
